<template>
	<ion-app>
		<ion-router-outlet id="main-content" />
	</ion-app>
</template>

<script>
import {
	IonApp,
	IonRouterOutlet,
} from '@ionic/vue'

import { StatusBar } from '@capacitor/status-bar'

export default {
	name: 'App',

	components: {
		IonApp,
		IonRouterOutlet,
	},

	async created() {
		await this.fetchTheme()
		StatusBar.setOverlaysWebView({ overlay: false })
	},

	// watch: {
	// 	isLoggedIn: {
	// 		immediate: true,
	// 		async handler() {
	// 			if (this.isLoggedIn && !!this.$store.getters['themeInfo/theme']) await this.fetchTheme()
	// 		},
	// 	},
	// },

	methods: {
		async fetchTheme() {
			try {
				this.$wToast.clear_loading()
				await this.$store.dispatch('themeInfo/fetchTheme')
			} catch (e) {
				await this.$wToast.error(e)
			}
		},
	},
}
</script>
