import axios from 'axios'

import { saveResponse, loadResponse } from './storage'

// Saving responses

// ak urobis request a vies ze si offline tak rov vrat data (nerob request)
// ako sa to sprava ked si offline ale posle sa request.

class Offline {
	// eslint-disable-next-line class-methods-use-this
	public shouldStoreOffline(config: any) { return false }

	constructor() {
		axios.interceptors.response.use(
			this._saveOffline.bind(this),
			this._loadOfflineData.bind(this),
		)
	}

	private _loadOfflineData(error: any) {
		// if(
		//     (!navigator.onLine && error.config) ||
		//     error.error.message == 'Failed to fetch'
		// ) {
		if (error.error == 'time out' || !navigator.onLine) {
			if (this.shouldStoreOffline(error.config)) {
				const offlineData = loadResponse(error.config.url)
				if (offlineData) {
					console.log('from offline', error.config.url)
					return Promise.resolve(JSON.parse(offlineData))
				}
			}
		}
		// }
		return Promise.reject(error)
	}

	private _saveOffline(response: any) {
		if (this.shouldStoreOffline(response.config)) {
			saveResponse(response.config.url, response)
		}
		return response
	}
}

export default new Offline()
