import axios from '@/plugins/w/axios'
import './refreshExpired'
import wStorage from '@/plugins/w/storage'

export default {
	namespaced: true,
	state: {
		status: '',
		_token: wStorage().get('wzo_vue_user_token', ''),
		user: wStorage().get('wzo_vue_user_user', {}),
	},
	mutations: {
		auth_request(state: any) {
			state.status = 'loading'
		},
		auth_success(state: any, data: any) {
			state.status = 'success'
			if (data.token) {
				state._token = data.token
				wStorage().set('wzo_vue_user_token', data.token)
			}

			if (data?.user?.group_id) {
				wStorage().set('vct_group_id', data.user.group_id)
			}

			if (data.user) {
				state.user = data.user
				wStorage().set('wzo_vue_user_user', data.user)
			}
		},
		auth_error(state: any) {
			state.status = 'error'
			state._token = ''
			wStorage().remove('wzo_vue_user_token')
			wStorage().remove('wzo_vue_user_user')
		},
		logout(state: any) {
			state.status = ''
			state._token = ''
			state.user = null
			wStorage().remove('wzo_vue_user_token')
			wStorage().remove('wzo_vue_user_user')
		},
	},
	actions: {
		login({ commit, dispatch }: {commit: any, dispatch: any},
			[credentials, config]: [credentials: any, config: any]) {
			return new Promise((resolve, reject) => {
				commit('auth_request')

				axios.post_data('v1/auth/login', credentials, config)
					.then((response: any) => {
						commit('auth_success', response)
						dispatch('themeInfo/fetchTheme', null, { root: true })

						resolve(response)
					}).catch((error) => {
						commit('auth_error')
						reject(error)
					})
			})
		},
		signup({ commit }: {commit: any}, [credentials, config]: [credentials:any, config:any]) {
			return new Promise((resolve, reject) => {
				commit('auth_request')

				axios.post_data('v1/auth/signup', credentials, config)
					.then((response: any) => {
						resolve(response)
					}).catch((error) => {
						commit('auth_error')
						reject(error)
					})
			})
		},
		logout({ commit }: {commit: any}) {
			return new Promise((resolve) => {
				axios.post_auth_data('v1/auth/invalidate')
					.finally(() => {
						commit('logout')
						commit('themeInfo/theme_remove', null, { root: true })
						resolve(null)
					})
			})
		},
		forgotPassword({}, credentials: any) {
			return new Promise((resolve, reject) => {
				axios.post_data('v1/auth/forgot', credentials, {
					headers: {
						groupid: process.env.VUE_APP_VCT_DEFAULT_AGENCYID,
					},
				})
					.then((response) => resolve(response))
					.catch((error) => reject(error))
			})
		},
		resetPassword({}, credentials: any) {
			return new Promise((resolve, reject) => {
				axios.post_auth_data('v1/auth/reset', credentials, {
					headers: {
						groupid: process.env.VUE_APP_VCT_DEFAULT_AGENCYID,
					},
				})
					.then((response) => resolve(response))
					.catch((error) => reject(error))
			})
		},
		async refreshToken({ commit }: {commit: any}) {
			try {
				const response: any = await axios.post_auth_data('v1/auth/refresh')

				const { token } = response.data.response
				const { user } = response.data.response

				commit('auth_success', {
					token,
					user,
				})

				return token
			} catch (error) {
				commit('auth_error')
				console.error(error)
				return error
			}
		},
		async userInfo({ commit }: {commit: any}) {
			return new Promise((resolve, reject) => {
				axios.get_auth('v1/auth/userinfo')
					.then((response: any) => {
						const { user } = response

						commit('auth_success', {
							user,
						})

						resolve(user)
					}).catch((error) => {
						reject(error)
					})
			})
		},
		updateUserInfo({ commit }: {commit: any}, data: any) {
			return new Promise((resolve, reject) => {
				axios.post_auth('v1/auth/userinfo', data)
					.then((response: any) => {
						const { user } = response

						commit('auth_success', {
							user,
						})

						resolve(user)
					}).catch((error) => {
						reject(error)
					})
			})
		},
	},
	getters: {
		isLoggedIn: (state: any) => !!state._token,
		authStatus: (state: any) => state.status,
		user: (state: any) => state.user,
		token: (state: any) => state._token,
		groupId: (state: any) => state?.user?.profile?.group_id || '',
	},
}
