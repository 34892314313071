<template>
	<div>
		<div v-if="!items">
			<template v-if="ion_spinner">
				<div class="flex flex-row justify-center items-center w-screen h-screen">
					<ion-spinner class="hidden" />
					<img src="@/plugins/assets/svg/loader-dots.svg" class="w-20">
				</div>
			</template>
			<template v-else>
				{{ msgLoading }}
			</template>
		</div>
		<div v-if="typeof items === 'object'">
			<div v-if="items && !Object.keys(items).length">
				{{ msgEmpty }}
			</div>
			<div v-else-if="items && items.error">
				{{ msgError }} {{ items.error }}
			</div>
			<slot v-else-if="items && Object.keys(items).length" />
		</div>
		<div v-else-if="Array.isArray(items)">
			<div v-if="items && !items.length">
				{{ msgEmpty }}
			</div>
			<slot v-else-if="items && items.length" />
		</div>
	</div>
</template>

<script>
import { IonSpinner } from '@ionic/vue'

export default {
	components: {
		IonSpinner,
	},
	props: {
		items: {
			type: [Object, Array],
			default: null,
		},
		ion_spinner: {
			type: Boolean,
			default: false,
		},
		msgLoading: {
			type: String,
			default: 'Načítavam...',
		},
		msgEmpty: {
			type: String,
			default: 'Nenachádzajú sa tu žiadne položky',
		},
		msgError: {
			type: String,
			default: 'Nastala chyba: ',
		},
	},
}
</script>
