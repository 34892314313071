import { createStore } from 'vuex'
import themeInfo from './themeInfo.store'

export default createStore({
	state: {
	},
	mutations: {
	},
	actions: {
	},
	modules: {
		themeInfo,
	},
})
