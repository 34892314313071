import store from '@/plugins/app/store'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export default async function (to:RouteLocationNormalized, from:RouteLocationNormalized,
	next:NavigationGuardNext) {
	if (to.matched.some((record:any) => record.meta.requiresAuth) && !store.getters['auth/isLoggedIn']) {
		return next({ name: 'login' })
	} if (to.matched.some((record:any) => !record.meta.requiresAuth && store.getters['auth/isLoggedIn'])) {
		return next({ name: 'dashboard' })
	}
	return next()
}
