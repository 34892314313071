import axios from 'axios'

import { saveResponse, loadResponse } from './storage'

class Cache {
	// eslint-disable-next-line no-unused-vars,class-methods-use-this
	public shouldCache(response: any) { return false }

	constructor() {
		axios.interceptors.response.use(this._cacheSavingInterceptor.bind(this))
	}

	public cacheAdapterPlug(config: any) {
		if (this.shouldCache(config)) {
			const cachedData = loadResponse(config.url)
			if (cachedData) {
				const parsedCache = JSON.parse(cachedData)
				parsedCache.fromCache = true
				console.log(`fromCache: ${config.url}`)
				if (parsedCache) {
					return new Promise((resolve) => { resolve(parsedCache) })
				}
			}
		}
		return false
	}

	// Helpers
	private _cacheSavingInterceptor(response: any) {
		if (this.shouldCache(response.config)) {
			saveResponse(response.config.url, response)
		}
		return response
	}
}

export default new Cache()
