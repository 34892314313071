import { createRouter, createWebHashHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import { wRoute, wRouteChildren } from '@/plugins/w/route'

function prefixRoutes(prefix: string, routes: Array<RouteRecordRaw>) {
	routes.map((route) => {
		// eslint-disable-next-line no-param-reassign
		route.path = `${prefix}/${route.path}`
		return route
	})
	console.log(routes)
	return routes
}

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		redirect: '/onboarding',
	},

	// lib@user
	wRoute('profile', '/dashboard/profile', () => import('@/plugins/lib@user/profile-info/profile-info.vue'), true),
	...prefixRoutes('/account', [
		wRoute('login', 'login', () => import('@/plugins/lib@user/guest-login/guest-login.vue')),
		wRoute('signup', 'signup-all', () => import('@/plugins/lib@user/sign-up/signup-all.vue')),
		wRoute('agency-select', 'agency-select', () => import('@/plugins/lib@agency/agency-select/agency-select.vue')),
		wRoute('login-with-email', 'login-email/:email?', () => import('@/plugins/lib@user/login/w-login.vue')),
		wRoute('signup-with-email', 'signup-email/:email?', () => import('@/plugins/lib@user/sign-up/signup.vue')),
		wRoute('forgot-password', 'forgot', () => import('@/plugins/lib@user/forgot/forgot.vue')),
		wRoute('reset-password', 'reset/:email?', () => import('@/plugins/lib@user/forgot/create-new-pwd.vue')),
		wRoute('reset-email', 'reset-sent/:email', () => import('@/plugins/lib@user/forgot/after-forgot-screen.vue')),
		wRoute('confirmation', 'confirmation/:email', () => import('@/plugins/lib@user/sign-up/confirmation.vue')),
	]),

	// onboarding
	wRoute('onboarding', '/onboarding', () => import('@/plugins/lib@pub/onboarding/onboarding.vue')),

	// app@home
	wRoute('dashboard', '/dashboard', () => import('@/plugins/app@home/dashboard/dashboard.vue'), true),

	// lib@agency
	wRoute('agency', '/agency', () => import('@/plugins/lib@agency/agency/agency.vue'), true),

	// app@tour
	wRouteChildren('/shift/:shift/', () => import('@/plugins/app@tour/_components/z-tour-bottom-tabs.vue'), [
		wRoute('latest shift blogs', 'blogs', () => import('@/plugins/app@tour/blogs/blogs.vue'), true),
		wRoute('timeline', 'timeline', () => import('@/plugins/app@tour/tour/tour.vue'), true),
	], true),
	...prefixRoutes('/shift/:shift/timeline', [
		wRoute('checklist', 'checklist', () => import('@/plugins/app@tour/checklist/checklist.vue'), true),
		wRoute('delegates', 'delegates', () => import('@/plugins/app@tour/delegates/delegates.vue'), true),
		wRoute('delegate', 'delegate/:delegateId', () => import('@/plugins/app@tour/delegate-info/delegate-info.vue'), true),
		wRoute('flight', 'flight/:flightId', () => import('@/plugins/app@tour/flight/flight.vue'), true),
		wRoute('destination', 'destination/:destinationId', () => import('@/plugins/app@tour/destination/destination.vue'), true),
		wRoute('accommodation', 'accommodation/:accommodationId', () => import('@/plugins/app@tour/accommodation/accommodation.vue'), true),
	]),

	// lib@blogs
	wRoute('blogs', '/blogs', () => import('@/plugins/lib@blogs/blogs/blogs.vue'), true),
	wRoute('blog', '/blog/:blogId', () => import('@/plugins/lib@blogs/blog/blog.vue'), true),

	// lib@catalogs
	wRoute('catalogs', '/catalogs', () => import('@/plugins/lib@catalogs/catalogs/catalogs.vue'), true),
	wRoute('catalog', '/catalog/:id', () => import('@/plugins/lib@catalogs/catalog/catalog.vue'), true),

]

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes,
})

export default router
