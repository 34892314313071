import wStorage from '@/plugins/w/storage'
import store from '@/plugins/app/store'

export default function useDefaultOptions() {
	// const groupid = store.getters['auth/groupId'] || wStorage().get('vct_group_id')
	const groupid = store.getters['auth/groupId']
	console.log(groupid)
	const authorization = store.getters['auth/token']

	const headers = {
		...groupid && { groupid },
		...authorization && {
			Authorization: `Bearer ${authorization}`,
		},
		'Content-Type': 'application/json',
		Accept: 'application/json',
	}

	return {
		headers: { ...headers },
	}
}
