import { App } 		from 'vue'
import { Options } 	from './types'
import auth 		from './auth.store'

export default {
	install: (app: App, options: Options) => {
		if (options && options.disabled == true) return

		if (!app.config.globalProperties.$store) console.warn('[w/auth] Could not initialize. App does not have vuex store added.')
		else app.config.globalProperties.$store.registerModule('auth', auth)
	},
}
