export default function wStorage() {
	const get = (key: string, def?: any) => {
		if (localStorage.getItem(key)) return JSON.parse(localStorage.getItem(key)!)
		return def
	}
	const set = (key: string, value: any) => {
		localStorage.setItem(key, JSON.stringify(value))
	}
	const remove = (key: string) => {
		localStorage.removeItem(key)
	}

	const _get_native = async () => {
		// future async storage getter
	}

	const _set_native = async () => {
		// future async storage setter
	}

	return {
		get,
		set,
		remove,
	}
}
// export async function set(key: string, value: any): Promise<void> {
// 	await Storage.set({
// 		key,
// 		value: JSON.stringify(value),
// 	})
// }
//
// export async function get(key: string): Promise<any> {
// 	const item = await <any>Storage.get({ key })
// 	return item && JSON.parse(item.value)
// }
//
// export async function remove(key: string): Promise<void> {
// 	await Storage.remove({
// 		key,
// 	})
// }
