export default {
	parseToErrors(data: string|Record<string, any>) {
		const errorsArray = this._parseToArrayOfStrings(data)

		return errorsArray.map((error) => error)
	},
	_parseToArrayOfStrings(data: string|Record<string, any>) {
		if (typeof data === 'string') {
			return [data]
		}

		if (data && data.error && typeof data.error === 'string') {
			return [data.error]
		}

		if (data && data.error && typeof data.error === 'object') {
			return Object.values(data.error)
		}

		if (data.data && data.data.error && typeof data.data.error === 'string') {
			return [data.data.error]
		}

		if (data && data.data && data.data.error && typeof data.data.error === 'object') {
			return Object.values(data.data.error)
		}

		if (data.statusText) {
			return [data.statusText]
		}

		return [data]
	},

}
