import moment from 'moment'

/*
    GET /api/v1.0/report/(type)/(date),
        - offline
        - cache
    GET /api/v1.0/recipient,
        - offline
    GET /api/v1.0/reporttype,
        - offline
    GET /api/v1.0/statistictype,
        - offline
    GET /api/v1.0/statistic/(type)/(period),
        - offline
        - cache
*/

export const shouldStore = (config: Record<string, any>): ''|'cache'|'offline' => {
	const { url } = config
	// if (url.includes('/statistic/')) {
	// 	const hash = url.split('#')[1]
	// 	const loadFromDate = hash.split('&')[1]
	// 	if (loadFromDate == null) {
	// 		console.log(`date :${loadFromDate} - cache`)
	// 		return 'cache'
	// 	}
	//
	// 	const today = moment(new Date()).format('YYYY-MM-DD')
	// 	if (!(loadFromDate <= today)) {
	// 		console.log(`date :${loadFromDate} - cache`)
	// 		return 'cache'
	// 	}
	// }
	// if (url.includes('/report/')) {
	// 	if (!url.includes(moment(new Date(Date.now()).format('YYYY-MM-DD')))) {
	// 		return 'cache'
	// 	}
	// }
	if (config.method == 'get') {
		return 'offline'
	}
	return ''
}
