import loginGuardIsLoggedIn from '@/plugins/w/authStore/loginGuardIfMetaSet'

export const wRoute = (title: string, path: string, component: any, requiresAuth = false) => ({
	path,
	name: title,
	meta: { title, requiresAuth },
	component,
	beforeEnter: loginGuardIsLoggedIn,
})

export const wRouteChildren = (path: string, component: any, children: Array<any>, requiresAuth = false) => ({
	path,
	meta: { requiresAuth },
	component,
	children,
	beforeEnter: loginGuardIsLoggedIn,
})

export const wRouteDefault = (redirect: string) => ({
	path: '',
	redirect,
})
