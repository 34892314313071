/* eslint-disable */
import wAxios from '@/plugins/w/axios'

import wStorage from '@/plugins/w/storage'

export default {
	// @ts-nocheck
	namespaced: true,
	state: {
		theme: {},
		agencyName: '',
		agencyImagePath: '',
		agencyImageLongPath: '',
		agencyCatalogueLink: '',
	},
	mutations: {
		theme_success(state: any, { agency }: {agency: any}) {
			state.theme = agency.theme
			state.agencyName = agency.name
			state.agencyImagePath = agency.image.path
			state.agencyImageLongPath = agency.image_long.path
			state.agencyCatalogueLink = agency.misc.catalogue_link
		},
		theme_remove(state: any) {
			state.theme = {}
			state.agencyName = ''
			state.agencyImagePath = ''
			state.agencyImageLongPath = ''
			state.agencyCatalogueLink = ''
		},
	},
	actions: {
		async fetchTheme({ commit, dispatch, rootGetters }: {commit: any, dispatch: any, rootGetters: any}) {
			const config = {
				headers: {
					groupid: wStorage().get('vct_group_id', process.env.VUE_APP_VCT_DEFAULT_AGENCYID),
				},
			}

			const data = await wAxios.get_auth('v1/agency', config)

			commit('theme_success', data)
			dispatch('_injectTheme')
		},
		_injectTheme({ getters }: { getters: any }) {
			console.log(getters.theme.colors.primary)
			const root = document.querySelector<HTMLElement>(':root')
			root?.style.setProperty('--agency-primary', getters.theme.colors.primary)
			root?.style.setProperty('--agency-secondary', getters.theme.colors.secondary)
		},
	},
	getters: {
		theme: (state: any) => state.theme,
		agencyName: (state: any) => state.agencyName,
		agencyImagePath: (state: any) => state.agencyImagePath,
		agencyImageLongPath: (state: any) => state.agencyImageLongPath,
		agencyCatalogueLink: (state: any) => state.agencyCatalogueLink,
	},
}
