/*
HOW TO:
    import wAxios from '@/plugins/w/axios'
    wAxios.get
    wAxios.post
    wAxios.get_auth
    wAxios.post_auth
*/
import axios from 'axios'
import { merge } from 'lodash'

// custom project configuration
import useDefaultOptions from '@/plugins/app/_utils/axios/default-options'
import offline from '@/plugins/w/axios-offline/offline'
import cache from '@/plugins/w/axios-offline/cache'

import { shouldStore } from '@/plugins/w/axios/offline'

axios.defaults.baseURL = process.env.VUE_APP_WAXIOS_URL

cache.shouldCache = (config) => shouldStore(config) == 'cache'
offline.shouldStoreOffline = (config) => shouldStore(config) == 'offline'

const defaultAdapter = axios.defaults.adapter

// @ts-expect-error idk
axios.defaults.adapter = (config) => {
	const cacheResp = cache.cacheAdapterPlug(config)
	if (cacheResp) {
		return cacheResp
	}

	// const nativeResp = native.nativeAdapterPlug(config)
	// if (nativeResp) {
	// 	return nativeResp
	// }

	const axiosConfig = Object.assign(config, { adapter: defaultAdapter })
	const axiosWithoutAdapter = axios.create(axiosConfig)
	return axiosWithoutAdapter.request(config)
}

export default {

	// GET Methods -------------------------------------------
	async get(url:string, config?:any):Promise<any> {
		return axios.get(url, merge(useDefaultOptions(), config))
			.then((response) => response.data)
			.catch((error) => Promise.reject(error))
	},

	async get_auth(url:string, config?:any):Promise<any> {
		console.log(config)
		return axios.get(url, merge(useDefaultOptions(), config))
			.then((response) => response.data)
			.catch((error) => Promise.reject(error))
	},

	async get_data(url:string, config?:any):Promise<any> {
		return axios.get(url, merge(useDefaultOptions(), config))
			.then((response) => response.data.data)
			.catch((error) => Promise.reject(error.response))
	},

	async get_auth_data(url:string, config?:any):Promise<any> {
		return axios.get(url, merge(useDefaultOptions(), config))
			.then((response) => response.data.data)
			.catch((error) => Promise.reject(error.response))
	},

	// POST methods -------------------------------------------

	async post(url:string, parameters:any = null, config?:any):Promise<any> {
		return axios.post(url, parameters, merge(useDefaultOptions(), config))
			.then((response) => response.data)
			.catch((error) => Promise.reject(error))
	},

	async post_auth(url:string, parameters:any = null, config?:any):Promise<any> {
		return axios.post(url, parameters, merge(useDefaultOptions(), config))
			.then((response) => response.data)
			.catch((error) => Promise.reject(error))
	},

	async post_data(url:string, params = null, config?:any):Promise<any> {
		return axios.post(url, params, merge(useDefaultOptions(), config))
			.then((response) => response.data)
			.catch((error) => Promise.reject(error.response))
	},

	async post_auth_data(url:string, parameters = null, config?:any):Promise<any> {
		return axios.post(url, parameters, merge(useDefaultOptions(), config))
			.then((response) => response.data.data)
			.catch((error) => Promise.reject(error.response))
	},

	// DELETE methods -------------------------------------------

	async delete(url:string, config?:any):Promise<any> {
		return axios.delete(url, merge(useDefaultOptions(), config))
			.then((response) => response.data)
			.catch((error) => Promise.reject(error))
	},

	async delete_auth(url:string, config?:any):Promise<any> {
		return axios.delete(url, merge(useDefaultOptions(), config))
			.then((response) => response.data)
			.catch((error) => Promise.reject(error))
	},

	async delete_data(url:string, config?:any):Promise<any> {
		return axios.delete(url, merge(useDefaultOptions(), config))
			.then((response) => response.data.data)
			.catch((error) => Promise.reject(error.response))
	},

	async delete_auth_data(url:string, config?: any|null):Promise<any> {
		return axios.delete(url, merge(useDefaultOptions(), config))
			.then((response) => response.data.data)
			.catch((error) => Promise.reject(error.response))
	},

	// PATCH methods -------------------------------------------

	async patch(url:string, parameters:any = null, config?:any):Promise<any> {
		return axios.patch(url, parameters, merge(useDefaultOptions(), config))
			.then((response) => response.data)
			.catch((error) => Promise.reject(error))
	},

	async patch_auth(url:string, parameters:any = null, config?:any):Promise<any> {
		return axios.patch(url, parameters, merge(useDefaultOptions(), config))
			.then((response) => response.data)
			.catch((error) => Promise.reject(error))
	},

	async patch_data(url:string, parameters:any = null, config?:any):Promise<any> {
		return axios.patch(url, parameters, merge(useDefaultOptions(), config))
			.then((response) => response.data.data)
			.catch((error) => Promise.reject(error.response))
	},

	async patch_auth_data(url:string, parameters = null, config?:any):Promise<any> {
		return axios.patch(url, parameters, merge(useDefaultOptions(), config))
			.then((response) => response.data.data)
			.catch((error) => Promise.reject(error.response))
	},
}
