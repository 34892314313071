// Components
import {
	IonContent,
	IonPage,
	IonLabel,
	IonBackButton,
	IonCheckbox,
	IonText,
	IonButton,
	IonItem,
	IonToolbar,
	IonHeader,
	IonFooter,
	IonSlides,
	IonSlide,
	IonRow,
	IonCol,
	IonGrid,
	IonInput,
	IonButtons,
	IonIcon,
	IonList,
	IonTitle,
	IonMenu,
	IonRouterOutlet,
	IonMenuButton,
	IonSegment,
	IonSegmentButton,
	IonVirtualScroll,
	IonCardSubtitle,
	IonTextarea,
	IonSearchbar,
	IonRefresherContent,
	IonRefresher,
	IonInfiniteScrollContent,
	IonInfiniteScroll,
	IonAvatar,
	IonModal,
	IonRadioGroup,
	IonRadio,
	IonNote,
	IonCard,
	IonCardContent,
	IonTabBar,
	IonTabButton,
	IonMenuToggle,
	IonFab,
	IonFabButton,
	IonReorderGroup,
	IonReorder,
	IonFabList,
	IonSelect,
	IonSelectOption,
	IonToggle,
	IonApp,
	IonItemSliding,
	IonItemOptions,
	IonItemOption,
	IonBadge,
	IonListHeader,
	IonCardTitle, IonCardHeader,
} from '@ionic/vue'

import { App } from 'vue'

export const registerIonicComponents = (app: App<Element>): void => {
	app.component('IonContent', IonContent)
	app.component('IonPage', IonPage)
	app.component('IonLabel', IonLabel)
	app.component('IonBackButton', IonBackButton)
	app.component('IonCheckbox', IonCheckbox)
	app.component('IonText', IonText)
	app.component('IonButton', IonButton)
	app.component('IonItem', IonItem)
	app.component('IonToolbar', IonToolbar)
	app.component('IonHeader', IonHeader)
	app.component('IonFooter', IonFooter)
	app.component('IonSlides', IonSlides)
	app.component('IonSlide', IonSlide)
	app.component('IonRow', IonRow)
	app.component('IonCol', IonCol)
	app.component('IonGrid', IonGrid)
	app.component('IonInput', IonInput)
	app.component('IonButtons', IonButtons)
	app.component('IonIcon', IonIcon)
	app.component('IonList', IonList)
	app.component('IonTitle', IonTitle)
	app.component('IonMenu', IonMenu)
	app.component('IonMenuButton', IonMenuButton)
	app.component('IonRouterOutlet', IonRouterOutlet)
	app.component('IonSegment', IonSegment)
	app.component('IonSegmentButton', IonSegmentButton)
	app.component('IonVirtualScroll', IonVirtualScroll)
	app.component('IonTextarea', IonTextarea)
	app.component('IonSearchbar', IonSearchbar)
	app.component('IonRefresherContent', IonRefresherContent)
	app.component('IonRefresher', IonRefresher)
	app.component('IonInfiniteScrollContent', IonInfiniteScrollContent)
	app.component('IonInfiniteScroll', IonInfiniteScroll)
	app.component('IonAvatar', IonAvatar)
	app.component('IonModal', IonModal)
	app.component('IonRadioGroup', IonRadioGroup)
	app.component('IonRadio', IonRadio)
	app.component('IonNote', IonNote)
	app.component('IonCardSubtitle', IonCardSubtitle)
	app.component('IonCard', IonCard)
	app.component('IonCardContent', IonCardContent)
	app.component('IonTabBar', IonTabBar)
	app.component('IonTabButton', IonTabButton)
	app.component('IonMenuToggle', IonMenuToggle)
	app.component('IonFab', IonFab)
	app.component('IonFabButton', IonFabButton)
	app.component('IonFabList', IonFabList)
	app.component('IonReorderGroup', IonReorderGroup)
	app.component('IonReorder', IonReorder)
	app.component('IonSelect', IonSelect)
	app.component('IonSelectOption', IonSelectOption)
	app.component('IonToggle', IonToggle)
	app.component('IonApp', IonApp)
	app.component('IonItemSliding', IonItemSliding)
	app.component('IonItemOptions', IonItemOptions)
	app.component('IonItemOption', IonItemOption)
	app.component('IonBadge', IonBadge)
	app.component('IonListHeader', IonListHeader)
	app.component('IonCardTitle', IonCardTitle)
	app.component('IonCardHeader', IonCardHeader)
}
