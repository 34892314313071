/* eslint-disable no-param-reassign */
import store from '@/plugins/app/store'
import axios from 'axios'
import router from '@/plugins/app/router'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

axios.interceptors.request.use((request) => {
	console.log('interceptor')
	// @ts-expect-error idk
	request.headers.Authorization = `Bearer ${store.getters['auth/token']}`
	return request
})
const handleRefreshError = async (failedRefresh: any) => {
	await store.dispatch('auth/logout')
	await router.push('/account/login')
	console.log(failedRefresh)
}

const refreshAuthLogic = (failedRequest: any) => store.dispatch('auth/refreshToken').then((refreshedToken) => {
	// const { user, token } = refreshedToken
	console.log(refreshedToken)
	failedRequest.response.config.headers.Authorization = `Bearer ${refreshedToken}`
	return Promise.resolve()
}).catch((error) => handleRefreshError(error))

createAuthRefreshInterceptor(axios, refreshAuthLogic)
