// vue / ionic stuff
import App from '@/app.vue'
import { createApp } from 'vue'
import { IonicVue } from '@ionic/vue'
import { registerIonicComponents } from '@/plugins/w/ionic'

import router from '@/plugins/app/router'
import store from '@/plugins/app/store'

// w/plugins
// import wAxios from '@/plugins/w/axios'
// @ts-expect-error bad type
import moxios from '@/plugins/w/axios-mocks'
import wToast from '@/plugins/w/toast'
import CmfInput from '@/plugins/cmf/cmf-input/cmf-input.vue'
import wLoadable from '@/plugins/w/loadable/w-loadable.vue'
import wStorage from '@/plugins/w/storage'

// custom libs
import mitt from 'mitt'
import moment from 'moment'
// eslint-disable-next-line
import Vue3TouchEvents from 'vue3-touch-events'

// theme
import '@/plugins/app/_theme/index.css'
import auth from '@/plugins/w/auth'

// custom lib initialization / settings
moment.locale('en')
const eventBus = mitt()

const app = createApp(App)
	.use(IonicVue, {
		swipeBackEnabled: false,
		mode: 'ios',
	})
	.use(router)
	.use(store)
	.use(Vue3TouchEvents)
	.use(auth)

router.isReady().then(() => {
	app.mount('#app')
})

// global variables / properties
app.config.globalProperties.eventBus = eventBus
app.config.globalProperties.$wToast = wToast
app.config.globalProperties.$wStorage = wStorage

// global components
registerIonicComponents(app)
app.component('CmfInput', CmfInput)
app.component('WLoadable', wLoadable)

// mocks
localStorage.setItem('isMoxios', 'true')
moxios.mock({
	// // auth
	// 'POST v1/auth/login': true,
	// 'POST v1/auth/forgot': true,
	// 'POST v1/auth/reset': true,
	// 'POST v1/auth/refresh': true,

	// // lib stuff
	// 'GET v1/shifts': true,
	// 'GET v1/shift/(sid)': true,
	// 'GET v1/catalogs': true,

	// // misc or idk...
	// 'GET v1/agency': true,

})
