<template>
	<div>
		<ion-item
			class="ion-no-padding mb-0 cmf-input-wrapper ion-text-left"
		>
			<ion-label :position="labelPosition" class="ion-text-wrap italic leading-4 mb-0 mt-4">
				{{ label }}
			</ion-label>
			<ion-input
				ref="input"
				v-model="fieldData"
				:disabled="readonly"
				class="pr-5 text-sm"
				:type="type"
				:placeholder="placeholder"
				@ionChange="changed($event)"
				@ionInput="$emit('cmfInput')"
			/>
			<div
				v-if="togglePassword"
				class="toggle-password flex items-center justify-center cursor-pointer"
			>
				<!--v-if="inputType == 'text'" @click="inputType = 'password'"-->
				<ion-icon
					v-touch:press="press"
					v-touch:release="release"
					:icon="icons.eyeOutline"
				/>
				<!--<ion-icon v-else :icon="icons.eyeOffOutline" @click="inputType = 'text' " />-->
			</div>
			<div v-if="(modelValue.$dirty && !togglePassword) || (vBe && vBe[name] && !togglePassword)">
				<img v-if="!hasError" src="../_assets/img/check-input.svg" alt="">
				<img v-if="hasError" src="../_assets/img/x-input.svg" alt="">
			</div>
		</ion-item>
		<!--{{ vBe }}-->
		<!--FE ERRORS-->
		<div v-if="!emitOutput && modelValue.$error && modelValue.$dirty" class="ion-no-margin">
			<div v-for="(error, index) in modelValue.$errors" :key="index" class="error">
				{{ validationErrors[error.$validator] }}
			</div>
		</div>
		<!--BE ERRORS -->
		<div v-if="!emitOutput && vBe && typeof vBe == 'object'">
			<div v-for="(error, index) in vBe[name]" :key="index" class="error">
				{{ displayError(error) }}
			</div>
		</div>
	</div>
</template>

<script>
import { eyeOutline, eyeOffOutline } from 'ionicons/icons'
import utils from '@/plugins/w/toast/utils'

export default {
	props: {
		modelValue: Object,
		label: {
			required: false,
			default: '',
		},
		type: {
			type: String,
			required: false,
			default: 'text',
		},
		togglePassword: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			required: false,
			default: '',
		},
		readonly: {
			type: Boolean,
			required: false,
			default: false,
		},
		labelPosition: {
			type: String,
			required: false,
			default: 'floating',
		},
		emitOutput: {
			type: Boolean,
			required: false,
			default: false,
		},
		vBe: {
			required: false,
		},
	},
	emits: ['update:modelValue', 'cmfInput'],
	data() {
		return {
			inputType: '',
			fieldData: null,
			name: '',
			validationErrors: {
				required: 'This field is mandatory.',
				email: 'Please enter valid email.',
				numeric: 'Field value must be number.',
				// minLength: `This field needs to be at least
				// ${this.modelValue.minLength ? this.modelValue.minLength.$params.min : ' '}
				// characters long.`,
				sameAsPassword: 'Passwords does not match.',
				agreed: 'You need to accept terms first.',
			},
			icons: {
				eyeOutline,
				eyeOffOutline,
			},
		}
	},
	computed: {
		hasError() {
			if (typeof this.vBe == 'string') {
				return true
			}
			if (this.vBe && this.vBe[this.name]) {
				return true
			}
			return !!(this.modelValue.$dirty && this.modelValue.$error)
		},
	},
	watch: {
		modelValue: {
			deep: true,
			handler(data) {
				this.fieldData = data.$model
			},
		},
		// '$props.vBe': {
		// 	handler(vBe) {
		// 		this.eventBus.emit('error', vBe)
		// 	},
		// 	deep: true,
		// 	immediate: true,
		// },
	},
	mounted() {
		this.name = this.modelValue.$path.split('.').pop()
		this.inputType = this.type
		this.fieldData = this.modelValue.$model
	},
	methods: {
		press() {
			if (this.type != 'password') return
			this.$refs.input.$el.type = 'text'
		},
		release() {
			if (this.type != 'password') return
			this.$refs.input.$el.type = 'password'
		},
		changed(e) {
			this.eventBus.emit('input', this.name)

			// idk if its the best solution but it does what $set() used to do
			const newValue = this.modelValue
			newValue.$model = e.target.value
			this.$emit('update:modelValue', newValue)
		},
		displayError(error) {
			return utils.parseToString(error)
		},
	},
}
</script>

<style scoped>

.cmf-input-wrapper {
	position: relative;
	margin-bottom: 10px;
}

img {
	position: absolute;
	right: 5px;
	bottom: 10px;
	z-index: 10;
}

.toggle-password {
	position: absolute;
	height: 22px;
	width: 22px;
	right: 5px;
	bottom: 10px;
	z-index: 10;
}

.error {
  color: red
}

.m-input > input {
	margin-right: 15px;
}
</style>
